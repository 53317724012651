

















































































import Vue from 'vue';
import XLSX from 'xlsx';
import vue2Dropzone from 'vue2-dropzone';
import RytrApi from '@/services/api/RytrApi';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { Component } from 'vue-property-decorator';
import DownloadButton from '@/components/common/DownloadButton.vue';

@Component({
    components: {
        vue2Dropzone,
        DownloadButton
    }
})
export default class Rytr extends Vue {
    // eslint-disable-next-line
    public options: Record<string, any> = {
        url: process.env.VUE_APP_BACKEND_URI + '/api/rytr/dropzone-mock',
        maxFiles: 1,
        addRemoveLinks: true
    }

    public file: File | null = null;

    public loading: boolean = false;

    public data: Record<string, string>[] = [];

    public isSeparateUseCaseMode: boolean = false;

    public isDownloadReady: boolean = false;

    public toneIdConvincing: string = '60572a639bdd4272b8fe358b';

    public languageIdEnglish: string = '607adac76f8fe5000c1e636d';

    public useCaseMagicCommandId: string = '60ed7113732a5b000cf99e8e';

    public useCaseJobDescriptionId: string = '60586b31cdebbb000c21058d';

    public useCaseBlogSectionWritingId: string = '60584cf2c2cdaa000c2a7954';

    public useCasesList: { title: string; value: string }[] = [
        { title: 'Magic Command', value: 'magic' },
        { title: 'Job Description Command', value: 'job' },
        { title: 'Blog Section Command', value: 'blog' }
    ];

    public requests: number = 1;

    public selectedUseCase: string = '';

    public process(): void {
        if (this.file === null) {
            this.$store.commit('notificationModule/setSnackBarNotification', {
                color: 'error',
                msg: 'Select file.'
            });

            return;
        }

        this.loading = true;

        this.isDownloadReady = false;

        if (this.isSeparateUseCaseMode) {
            this.getUseCaseData(this.data);
        } else {
            const chunkedData = this.getChunkedData(this.data);

            this.getAllUseCasesData(chunkedData);
        }
    }

    public async getUseCaseData(data: Array<Record<string, string>>): Promise<void> {
        /* eslint-disable */
        try {
            let useCaseId = '';
            let rytrCommand = '';
            const rytrApi = new RytrApi(this.toneIdConvincing, this.languageIdEnglish);

            switch (this.selectedUseCase) {
                case 'magic': {
                    useCaseId = this.useCaseMagicCommandId;
                    rytrCommand = 'rytrMagicCommand';
                    break;
                }
                case 'job': {
                    useCaseId = this.useCaseJobDescriptionId;
                    rytrCommand = 'rytrJobDescriptionCommand';
                    break;
                }
                case 'blog': {
                    useCaseId = this.useCaseBlogSectionWritingId;
                    rytrCommand = 'rytrBlogSectionWritingCommand';
                    break;
                }
                default: {
                    console.error('Empty action received.');
                    break;
                }
            }

            const useCaseCommand = await rytrApi.caseDetailById(useCaseId);

            for (let index = 0; index < data.length; index++) {
                const rytrInputField = data[index][0];
                const rytrInputFieldAdditional = data[index][1];

                if (this.requests === 1) {
                    const rytrResponse = await (rytrApi as any)[rytrCommand](rytrInputField, index, rytrInputField, useCaseCommand, rytrInputFieldAdditional);
                    data[index][0] = rytrResponse.text;

                    await this.sleep(1500);
                } else {
                    for (let i = 0; i < this.requests; i++) {
                        const rytrResponse = await (rytrApi as any)[rytrCommand](rytrInputField, index, rytrInputField, useCaseCommand, rytrInputFieldAdditional);
                        data[index][i] = rytrResponse.text;

                        await this.sleep(1500);
                    }
                }
            }

            this.isDownloadReady = true;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    // @TODO separate service
    public async getAllUseCasesData(data: Array<Record<string, string>[]>): Promise<void> {
        try {
            const rytrApi = new RytrApi(this.toneIdConvincing, this.languageIdEnglish);

            const response = await Promise.all([
                rytrApi.caseDetailById(this.useCaseMagicCommandId),
                rytrApi.caseDetailById(this.useCaseJobDescriptionId),
                rytrApi.caseDetailById(this.useCaseBlogSectionWritingId)
            ]);

            const useCaseMagicCommand = response[0];
            const useCaseJobDescription = response[1];
            const useCaseBlogSectionWriting = response[2];

            for (let key = 0; key < data.length; key++) {
                const chunk = data[key];

                for (let index = 0; index < chunk.length; index++) {
                    const promises = [];
                    const row = chunk[index];

                    promises.push(rytrApi.rytrMagicCommand(row['Objective Input ( magic )'], index, 'Objective Output', useCaseMagicCommand));
                    promises.push(rytrApi.rytrMagicCommand(row['Skills Input ( magic )'], index, 'Skills Output', useCaseMagicCommand));
                    promises.push(rytrApi.rytrJobDescriptionCommand(row['Duties Input 1 ( job description )'], index, 'duties1', useCaseJobDescription));
                    promises.push(rytrApi.rytrJobDescriptionCommand(row['Duties Input 2 ( job description )'], index, 'duties2', useCaseJobDescription));
                    promises.push(rytrApi.rytrJobDescriptionCommand(row['Duties Input 3 ( job description )'], index, 'duties3', useCaseJobDescription));
                    promises.push(rytrApi.rytrBlogSectionWritingCommand(row['Intro Input ( blog section writing)'], index, 'Intro Output', useCaseBlogSectionWriting, row['Intro input ( keywords )']));
                    promises.push(rytrApi.rytrJobDescriptionCommand(row['H2 - extra duties Input'], index, 'H2 - extra duties', useCaseJobDescription));
                    promises.push(rytrApi.rytrJobDescriptionCommand(row['H2 - extra duties Input 2'], index, 'H2 - extra duties 2', useCaseJobDescription));
                    promises.push(rytrApi.rytrMagicCommand(row['H2 - Extra skills'], index, 'H2 - extra skills', useCaseMagicCommand));
                    promises.push(rytrApi.rytrMagicCommand(row['H2 - extra objectives INPUT ( magic )'], index, 'H2 - extra objectives', useCaseMagicCommand));
                    promises.push(rytrApi.rytrMagicCommand(row['H2 - extra objectives INPUT 2 ( magic )'], index, 'H2 - extra objectives 2', useCaseMagicCommand));
                    promises.push(rytrApi.rytrMagicCommand(row['H2 - extra objectives INPUT 3 ( magic )'], index, 'H2 - extra objectives 3', useCaseMagicCommand));

                    Promise.all(promises).then(response => {
                        response.forEach(item => {
                            chunk[item.index][item.resultCol] = item.text;
                        });
                    });
                }

                await this.sleep(15000);
            }

            this.isDownloadReady = true;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    public sleep(ms: number): Promise<string> {
        return new Promise(resolve => setTimeout(() => { resolve(''); }, ms));
    }

    public getChunkedData(data: Record<string, string>[]): Array<Record<string, string>[]> {
        const chunk = 3;

        return data.reduce((arr: Array<Record<string, string>[]>, item, index) => {
            const chunkIndex = Math.floor(index / chunk);

            if (!arr[chunkIndex]) {
                arr[chunkIndex] = [];
            }

            arr[chunkIndex].push(item);

            return arr;
        }, []);
    }

    public onFilePicked(file: File): void {
        console.log(process.env.VUE_APP_BACKEND_URI + '/api/rytr/dropzone-mock');
        if (file) {
            console.log(11);
            this.file = file;

            const reader = new FileReader();

            reader.onload = () => {
                const data = reader.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const wsname = workbook.SheetNames[0];
                const ws = workbook.Sheets[wsname];

                this.data = XLSX.utils.sheet_to_json(ws, {
                    defval: '',
                    header: this.isSeparateUseCaseMode ? 1 : undefined
                });
            };

            reader.readAsBinaryString(file);
        }
    }

    public maxFilesReached(file: File) {
        // eslint-disable-next-line
        (this.$refs.dropzone as any).removeAllFiles();
        // eslint-disable-next-line
        (this.$refs.dropzone as any).addFile(file);
    }
}
