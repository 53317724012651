/* eslint-disable */

import axios from 'axios';

export default class RytrApi {
    private apiKey: string = 'YKCNWU1PNAAG8SVRJ7NIH';

    private toneIdConvincing: string;

    private languageIdEnglish: string;

    private headers: Record<string, string > = {
        Authentication: `Bearer ${this.apiKey}`,
        'Content-Type': 'application/json'
    };

    constructor(
        toneIdConvincing: string,
        languageIdEnglish: string
    ) {
        this.toneIdConvincing = toneIdConvincing;
        this.languageIdEnglish = languageIdEnglish;
    }

    public caseDetailById = async (useCaseId: string): Promise<any> => {
        try {
            const { data } = await axios({
                method: 'get',
                url: `https://api.rytr.me/v1/use-cases/${useCaseId}`,
                headers: this.headers
            });

            return data ? data.data : null;
        } catch (e) {
            console.error(e);
        }
    }

    private ryte = async ({ useCaseId, inputContexts }: { useCaseId: string; inputContexts: any }) => {
        try {
            const { data } = await axios({
                method: 'post',
                url: 'https://api.rytr.me/v1/ryte',
                headers: this.headers,
                data: {
                    languageId: this.languageIdEnglish,
                    toneId: this.toneIdConvincing,
                    useCaseId,
                    inputContexts,
                    variations: 1,
                    userId: 'USER1',
                    format: 'text'
                }
            });

            return data ? data.data : null;
        } catch (error) {
            console.log(error);
        }

        return null;
    }

    public rytrMagicCommand = async (
        magicCommand: string,
        index: number,
        resultCol: string,
        useCaseMagicCommand: Record<string, any>
    ) => {
        const inputContexts = {
            [useCaseMagicCommand.contextInputs[0].keyLabel]: magicCommand
        };

        const output = await this.ryte({
            useCaseId: useCaseMagicCommand._id,
            inputContexts
        });

        return {
            text: output ? output[0].text : '---',
            index,
            resultCol
        };
    }

    public rytrJobDescriptionCommand = async (
        jobTitle: string,
        index: number,
        resultCol: string,
        useCaseJobDescription: Record<string, any>
    ) => {
        const inputContexts = {
            [useCaseJobDescription.contextInputs[0].keyLabel]: jobTitle
        };

        const output = await this.ryte({
            useCaseId: useCaseJobDescription._id,
            inputContexts
        });

        return {
            text: output ? output[0].text : '---',
            index,
            resultCol
        };
    }

    public rytrBlogSectionWritingCommand = async (
        topic: string,
        index: number,
        resultCol: string,
        useCaseBlogSectionWriting: Record<string, any>,
        keywords: string
    ) => {
        const inputContexts = {
            [useCaseBlogSectionWriting.contextInputs[0].keyLabel]: topic,
            [useCaseBlogSectionWriting.contextInputs[1].keyLabel]: keywords
        };

        const output = await this.ryte({
            useCaseId: useCaseBlogSectionWriting._id,
            inputContexts
        });

        return {
            text: output ? output[0].text : '---',
            index,
            resultCol
        };
    }
}
