




import Vue from 'vue';
import XLSX from 'xlsx';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DownloadButton extends Vue {
    @Prop({ required: true, type: Array })
    public data!: any[];

    @Prop({ required: true, type: String })
    public fileType!: string;

    @Prop({ required: true, type: String })
    public ext!: string;

    @Prop({ required: true, type: Boolean })
    public skipHeader!: boolean;

    public download(): void {
        const ws = XLSX.utils.json_to_sheet(this.data, { skipHeader: this.skipHeader });
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const buffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const data = new Blob([buffer], { type: this.fileType });

        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        const fileName = 'result' + '_' + date + '_' + time;

        const link = document.createElement('a');
        link.href = (window as any).URL.createObjectURL(data);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
